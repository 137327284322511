import type { CustomerProperties } from '@api/customer'
import type {
  AutomationConditionOperator,
  Customer,
  ListCondition,
  ListConditionOperator,
  ListConditionOperatorOp,
  ListConditionOperatorValue,
  ListEvent,
  ListRule,
  Subscription,
  SubscriptionPlan,
} from '@api/types'
import type { HslColor } from '@hello-pangea/color-picker'
import { type ClassValue, clsx } from 'clsx'
import {
  capitalize,
  isArray,
  kebabCase,
  lastIndexOf,
  omit,
  startCase,
  transform,
} from 'lodash'
import isPlainObject from 'lodash/isPlainObject'
import { DateTime, type Duration, Interval } from 'luxon'
import type { SortingRule } from 'react-table'
import { default as _slugify } from 'slugify'
import { SegmentedMessage } from 'sms-segments-calculator'
import { twMerge } from 'tailwind-merge'
import {
  ORDINAL_PROPERTIES,
  STRING_OPERATORS,
  customerPropertyOptions,
} from './constants/lists'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`
}

export const complianceMessage = 'Reply STOP to optout'

export function withComplianceMessage(input: string) {
  return `${input}\n\n${complianceMessage}`
}

export const isServer = typeof window === 'undefined'

export function slugify(input: string) {
  return _slugify(input, {
    lower: true,
    trim: true,
  })
}

export function pluralize(noun: string, count: number): string {
  if (count === 1) return noun
  if (lastIndexOf(noun, 'y') === noun.length - 1) {
    return noun.substring(0, noun.length - 1) + 'ies'
  }
  return `${noun}s`
}

export function calculateSegments(text: string): number {
  const message = new SegmentedMessage(text, 'auto', true)
  return message.segmentsCount
}

export function toFixed(value: number, fractionDigits: number): string {
  return value.toFixed(fractionDigits).replace(/\.?0+$/gi, '')
}

export function formatNumber(
  value?: number | string | null,
  options: { precision?: number } = { precision: 0 }
): string {
  return Number(value ?? 0)
    .toFixed(options.precision)
    .toLocaleString()
}

export function formatPercent(
  value?: number,
  options: { precision?: number } = { precision: 2 }
): string {
  if (!value) return '0%'
  return `${Number(value * 100)
    .toFixed(options.precision)
    .toLocaleString()}%`
}

export function formatMessageQuota(value?: number | string | null): string {
  if (typeof value === 'number' && value >= 1000000) {
    return 'Unlimited'
  } else {
    return Number(value ?? 0).toLocaleString()
  }
}

export function formatDate(
  value: Date | string,
  format?: string,
  options?: { zone?: string }
): string {
  const date = new Date(value)
  return DateTime.fromJSDate(date, options).toFormat(format || 'ff')
}

export function flattenObject(
  obj: Record<string, string>,
  prefix = ''
): Record<string, any> {
  return transform(
    obj,
    (result: Record<string, any>, value: any, key: string) => {
      const newKey = prefix ? `${prefix}.${key}` : key
      if (isPlainObject(value)) {
        Object.assign(result, flattenObject(value, newKey))
      } else if (isArray(value)) {
        value.forEach((item, index) => {
          Object.assign(result, flattenObject(item, `${newKey}[${index}]`))
        })
      } else {
        if (value !== '' && value !== null && value !== undefined) {
          result[newKey] = `${value}`
        }
      }
    },
    {}
  )
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

type NullishStrNum = string | number | null | undefined

export function parseNumber(defaultVal?: NullishStrNum) {
  return (val: string): NullishStrNum => {
    return val === '' ? defaultVal : val?.replace(/[^0-9.]/g, '')
  }
}

export function sanitizePhoneNumber(num: string) {
  return num?.replace(/[^0-9]/g, '')
}

export function isValidPhoneNumber(num: string) {
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im
  return phoneRegex.test(num)
}

export function formatPhoneNumber(
  num: string,
  options: { international: boolean } = { international: false }
) {
  // biome-ignore lint/style/useTemplate: <explanation>
  const cleaned = ('' + num).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [
      options.international ? intlCode : '',
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
    ].join('')
  }
  return num
}

export function getInitials(string: string) {
  const names = string.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

/**
 *
 * @param {Number} num
 * @returns {String} 1K, 1M, 1B
 */
export const abbreviateNumberByMagnitude = (
  num: number,
  { fixed }: any = {}
): string => {
  const fix = (val: number) => (fixed ? val.toFixed(fixed) : val)
  if (num >= 1000000000000) {
    return `${fix(num / 1000000000000)}T`
  } else if (num >= 1000000000) {
    return `${fix(num / 1000000000)}B`
  } else if (num >= 1000000) {
    return `${fix(num / 1000000)}M`
  } else if (num >= 1000) {
    return `${fix(num / 1000)}K`
  } else {
    return `${num}`
  }
}

export function buildQueryString({
  globalFilter,
  filters,
  sortBy,
  page,
}: {
  globalFilter?: string
  filters?: any
  sortBy?: SortingRule<object>[]
  page?: number
}) {
  const params = new URLSearchParams()
  if (globalFilter) {
    params.set('query', globalFilter)
  }
  if (filters && filters.length > 0) {
    params.set('filters', JSON.stringify(filters))
  }
  if (sortBy && sortBy.length > 0) {
    const sortString = sortBy
      .map(({ id, desc }) => `${id}:${desc ? 'desc' : 'asc'}`)
      .join(',')
    params.set('sort', sortString)
  }
  if (page) {
    params.set('page', String(page))
  }
  return params.toString()
}

export function buildSortArray(
  sortByParam: string | string[]
): SortingRule<object>[] {
  const sortByArray = Array.isArray(sortByParam) ? sortByParam : [sortByParam]

  return sortByArray.map((s: string) => {
    const [id, order] = s.split(':')
    return { id, desc: order === 'desc' }
  })
}

export function getCustomerName(customer: Customer): string {
  if (!customer) return ''
  const fullName = [customer.firstName, customer.lastName]
    .filter(Boolean)
    .join(' ')
  return fullName ? startCase(capitalize(fullName)) : 'New Customer'
}

export function getCustomerAvatarSrc(customer: Customer): string {
  const customerSource = getCustomerSource(customer)
  const avatarSrc =
    customerSource === 'mindbody'
      ? customer.properties?.mindbody?.PhotoUrl
      : customerSource === 'tablelist'
        ? customer.properties.tablelist?.profileImage
        : null
  return avatarSrc
}

export function getCustomerSource(customer: Customer): string {
  return customer?.source ? kebabCase(customer.source) : 'helios'
}

export function booleanToYesNo(boolean: boolean): string {
  return boolean ? 'Yes' : 'No'
}

export function formatCurrency(amount: string | number) {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function parseRulesForApi(rules: ListRule[]): any[] {
  return rules.map((r) => {
    const { type, rule } = r
    if (type === 'condition') {
      return {
        type: 'condition',
        rule: parseConditionsForApi([rule])[0],
      }
    } else {
      return {
        type: 'event',
        rule: parseEventsForApi(rule),
      }
    }
  })
}

export function parseEventsForApi(event: ListEvent): ListEvent {
  const { type, event: eventType, count, conditions } = event
  const parsedConditions = parseConditionsForApi(conditions)
  const parsedCount = count ? parseCountForApi(count) : null

  return {
    type,
    event: eventType,
    count: parsedCount,
    conditions: parsedConditions,
  }
}

export function parseCountForApi(
  count: ListConditionOperator
): ListConditionOperator {
  const { op, value } = count
  let parsedOp = op
  let parsedValue = Number(value)
  if (op === '$exists') {
    parsedOp = '$gte'
    parsedValue = 1
  }

  return { op: parsedOp, value: parsedValue }
}

export function parseConditionsForApi(
  conditions: ListCondition[]
): ListCondition[] {
  return conditions.map((condition) => {
    const { type, operators } = condition

    const coercedOperators: ListConditionOperator[] = []

    operators.forEach((operator) => {
      let coercedOp: ListConditionOperatorOp | undefined = undefined
      let coercedValue: ListConditionOperatorValue

      switch (type) {
        case 'number':
          if (['$last', '$next'].includes(operator.op)) {
            coercedValue = handleOrdinalOperator(operator, coercedOperators)
            coercedOp =
              operator.op === '$last'
                ? '$gte'
                : operator.op === '$next'
                  ? '$lte'
                  : undefined
          } else {
            coercedValue = Number(operator.value)
          }
          break
        case 'boolean':
          coercedValue = operator.value && operator.value === 'true'
          break
        // biome-ignore lint/suspicious/noFallthroughSwitchClause: <explanation>
        case 'date':
          if (['$last', '$next'].includes(operator.op)) {
            coercedValue = handleDateOperator(operator, coercedOperators)
            coercedOp =
              operator.op === '$last'
                ? '$gte'
                : operator.op === '$next'
                  ? '$lte'
                  : undefined
            break
          }
        case 'string':
          if (['$exists'].includes(operator.op)) {
            coercedValue = operator.value && operator.value === 'true'
          } else {
            coercedValue = operator.value
          }
          break
        // case 'enum':
        default:
          coercedValue = operator.value
      }

      if (coercedOp || operator.op) {
        coercedOperators.push({
          op: coercedOp || operator.op,
          value: coercedValue,
        })
      }
    })

    return omit({ ...condition, operators: coercedOperators }, [
      'condition',
      'type',
    ])
  })
}

function handleDateOperator(
  operator: ListConditionOperator,
  coercedOperators: ListConditionOperator[]
): ListConditionOperatorValue {
  const coercedValue: any = { $now: {} }

  if (
    operator.value?.$now?.duration_unit &&
    operator.value?.$now?.duration_value
  ) {
    const durationValue = Number(operator.value.$now.duration_value)
    coercedValue.$now[operator.value.$now.duration_unit] =
      operator.op === '$last' ? durationValue * -1 : durationValue

    if (operator.op === '$next') {
      // Add the second operator when the current operator is $next
      coercedOperators.push({ op: '$gte', value: { $now: { days: 0 } } })
    }
  }

  return coercedValue
}

function handleOrdinalOperator(
  operator: ListConditionOperator,
  coercedOperators: ListConditionOperator[]
): ListConditionOperatorValue {
  let coercedValue: any = {}

  if (operator.op === '$next') {
    coercedValue = { $ordinal: Number(operator.value?.$lte?.$ordinal) }
    coercedOperators.push({ op: '$gt', value: { $ordinal: 0 } })
  }
  if (operator.op === '$last') {
    coercedValue = { $ordinal: Number(operator.value?.$gte?.$ordinal) * -1 }
    coercedOperators.push({ op: '$lt', value: { $ordinal: 0 } })
  }

  return coercedValue
}

export function parseRulesFromApi(
  rules: any[],
  customerProperties?: CustomerProperties
): ListRule[] {
  return rules.map((r) => {
    const { type, rule } = r
    if (type === 'condition') {
      return {
        type: 'condition',
        rule: parseConditionsFromApi([rule], customerProperties)[0],
      }
    } else {
      return {
        type: 'event',
        rule: parseEventsFromApi(rule),
      }
    }
  })
}

export function parseEventsFromApi(event: ListEvent): ListEvent {
  const { type, event: eventType, count, conditions } = event
  const parsedConditions = parseConditionsFromApi(conditions)
  const parsedCount = count ? parseCountFromApi(count) : null

  return {
    type,
    event: eventType,
    count: parsedCount,
    conditions: parsedConditions,
  }
}

export function parseCountFromApi(
  count: ListConditionOperator
): ListConditionOperator {
  const { op, value } = count
  let parsedOp = op
  let parsedValue = Number(value)
  if (op === '$gte' && value === 1) {
    parsedOp = '$exists'
    parsedValue = 1
  }

  return { op: parsedOp, value: parsedValue }
}
export function parseConditionsFromApi(
  conditions: ListCondition[],
  customerProperties?: CustomerProperties
): ListCondition[] {
  return conditions.map((condition) => {
    const { key } = condition
    const options = []
    if (customerProperties) {
      options.push(...customerPropertyOptions(customerProperties).options)
    }
    const conditionKey = options.find((k) => k.value === key) as {
      condition?: any
      type?: any
      value?: string
    }
    if (conditionKey?.condition) condition.condition = conditionKey.condition
    if (conditionKey?.type) condition.type = conditionKey.type

    // Check if string operator is $exists and use boolean value
    if (conditionKey?.type === 'string') {
      const parsedOperators: ListConditionOperator[] = []
      condition.operators.forEach((operator) => {
        const operatorOp: ListConditionOperatorOp = operator.op
        let operatorValue: ListConditionOperatorValue = operator.value

        if (operator.op === '$exists') {
          operatorValue = String(operatorValue === true)
        }
        parsedOperators.push({ op: operatorOp, value: operatorValue })
      })

      condition.operators = parsedOperators
    } else if (conditionKey?.type === 'date') {
      const parsedOperators: ListConditionOperator[] = []

      // Check if the array contains a $next operator
      const hasNextOperator = condition.operators.some(
        (op) => op.op === '$lte' && op.value.$now && op.value.$now.days >= 0
      )

      // Filter out the $gte operator with a value of 0 days if there's a $next operator
      const filteredOperators = hasNextOperator
        ? condition.operators.filter(
            (op) =>
              op.op !== '$gte' || !op.value.$now || op.value.$now.days !== 0
          )
        : condition.operators

      filteredOperators.forEach((operator) => {
        let operatorOp: ListConditionOperatorOp = operator.op
        let operatorValue: ListConditionOperatorValue = operator.value

        if (operator.value.$now) {
          const durationUnit = Object.keys(operator.value.$now)[0]
          const durationValue = operator.value.$now[durationUnit]

          if (operator.op === '$gte' && durationValue < 0) {
            operatorOp = '$last'
            operatorValue = {
              $now: {
                duration_unit: durationUnit,
                duration_value: String(Math.abs(durationValue)),
              },
            }
          } else if (operator.op === '$lte' && durationValue >= 0) {
            operatorOp = '$next'
            operatorValue = {
              $now: {
                duration_unit: durationUnit,
                duration_value: String(durationValue),
              },
            }
          }
        }

        parsedOperators.push({ op: operatorOp, value: operatorValue })
      })

      condition.operators = parsedOperators
    } else if (ORDINAL_PROPERTIES.has(conditionKey?.value as string)) {
      const parsedOperators: ListConditionOperator[] = []

      const hasLte = condition.operators.some((op) => op.op === '$lte')
      const hasGte = condition.operators.some((op) => op.op === '$gte')

      if (hasGte) {
        const operatorValue = condition.operators.find(
          (op) => op.op === '$gte'
        )?.value
        parsedOperators.push({
          op: '$last',
          value: { $gte: { $ordinal: operatorValue?.$ordinal * -1 } },
        })
      } else if (hasLte) {
        const operatorValue = condition.operators.find(
          (op) => op.op === '$lte'
        )?.value
        parsedOperators.push({
          op: '$next',
          value: { $lte: { $ordinal: operatorValue?.$ordinal } },
        })
      } else {
        condition.operators.forEach((operator) => {
          let operatorOp: ListConditionOperatorOp = operator.op
          let operatorValue: ListConditionOperatorValue = operator.value

          if (operator.value.$ordinal) {
            if (operator.op === '$gte') {
              operatorOp = '$next'
              operatorValue = { $lte: { $ordinal: operator.value.$ordinal } }
            } else if (operator.op === '$lte') {
              operatorOp = '$last'
              operatorValue = { $gte: { $ordinal: operator.value.$ordinal } }
            }
          }

          parsedOperators.push({ op: operatorOp, value: operatorValue })
        })
      }

      condition.operators = parsedOperators
    }

    return condition
  })
}

// formatHslColor function
export function formatHslColor(hslString: string): HslColor {
  const [h, s, l] = hslString.split(' ').map((value, index) => {
    // Convert percentage to decimal for 's' and 'l'
    return index > 0 ? Number.parseFloat(value) / 100 : Number.parseInt(value)
  })

  return { h, s, l }
}

// parseHslColor function
export function parseHslColor(hslColor: HslColor): string {
  const { h, s, l } = hslColor
  // Convert 's' and 'l' from decimal to percentage
  return `${h} ${Math.round(s * 100)}% ${Math.round(l * 100)}%`
}

type DataItem = { [key: string]: any }

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${c.charCodeAt(0).toString(16).padStart(2, '0')}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function parseOrdinalNumber(number: any): string {
  const _number = Number(number)
  function ordinal(n: number) {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }
  return ordinal(_number)
}

export const COLOR_SCALE = [
  '#03045eff',
  '#023e8aff',
  '#0077b6ff',
  '#0096c7ff',
  '#00b4d8ff',
  '#48cae4ff',
  '#90e0efff',
  '#ade8f4ff',
  '#caf0f8ff',
]

interface Category {
  title: string
}
interface Article {
  title: string
  slug: {
    current: string
  }
  categories?: Category[]
}
type GroupedArticles = {
  [category: string]: Partial<Article>[]
}
export function groupArticlesByCategory(articles: Article[]): GroupedArticles {
  return articles.reduce(
    (grouped: GroupedArticles, article: Partial<Article>) => {
      article.categories?.forEach((category) => {
        const categoryTitle = category.title
        if (!grouped[categoryTitle]) {
          grouped[categoryTitle] = []
        }
        grouped[categoryTitle].push(article)
      })
      return grouped
    },
    {}
  )
}

export function isDateWithinInterval(
  date: Date | string,
  interval: { duration: number; unit: keyof Duration }
): boolean {
  const dateTime = DateTime.fromJSDate(new Date(date))
  const now = DateTime.now()
  const durationAgo = now.minus({ [interval.unit]: interval.duration })

  return dateTime >= durationAgo
}

export function formatDateRelative(input: Date | string): string {
  const now = DateTime.now()
  const inputDate = DateTime.fromJSDate(new Date(input))
  const diffInDays = Interval.fromDateTimes(inputDate, now).length('days')

  if (diffInDays < 1) {
    // If the date is within 1 day, use relative words
    return inputDate.toRelative() || ''
  } else if (diffInDays < 7) {
    // If the date is within 1 week, use 'Monday, Tuesday at hh:mm a'
    return inputDate.toFormat('EEEE, h:mm a')
  } else if (now.year === inputDate.year) {
    // If the date is later than 1 week but within the same year, use 'Mar 3, hh:mm a'
    return inputDate.toFormat('MMM d, h:mm a')
  } else {
    // If the date is from a different year, use 'dd/mm/yy, hh:mm a'
    return inputDate.toFormat('MMM d yyyy, h:mm a')
  }
}

export function getAutomationConditionOperatorName(
  op: AutomationConditionOperator['op']
): string {
  return STRING_OPERATORS.find((operator) => operator.value === op)?.label || ''
}

export function insertContentIntoTextarea({
  name,
  textareaRef,
  setValue,
  content,
}: {
  name: string
  textareaRef: React.RefObject<HTMLTextAreaElement>
  setValue: (name: string, value: string) => void
  content: string
}) {
  const textarea = textareaRef.current
  if (!textarea) return

  const selectionStart = textarea.selectionStart
  const selectionEnd = textarea.selectionEnd
  const newValue =
    textarea.value.slice(0, selectionStart) +
    content +
    textarea.value.slice(selectionEnd)

  setValue(name, newValue)

  // Calculate new cursor position
  const newCursorPosition = selectionStart + content.length
  // Focus the textarea and set cursor to new position
  setTimeout(() => {
    textarea.focus()
    textarea.setSelectionRange(newCursorPosition, newCursorPosition)
  }, 0)
}

/**
 * regular expression to check for valid hour format (01-23)
 */
export function isValidHour(value: string) {
  return /^(0[0-9]|1[0-9]|2[0-3])$/.test(value)
}

/**
 * regular expression to check for valid 12 hour format (01-12)
 */
export function isValid12Hour(value: string) {
  return /^(0[1-9]|1[0-2])$/.test(value)
}

/**
 * regular expression to check for valid minute format (00-59)
 */
export function isValidMinuteOrSecond(value: string) {
  return /^[0-5][0-9]$/.test(value)
}

type GetValidNumberConfig = { max: number; min?: number; loop?: boolean }

export function getValidNumber(
  value: string,
  { max, min = 0, loop = false }: GetValidNumberConfig
) {
  let numericValue = Number.parseInt(value, 10)

  if (!Number.isNaN(numericValue)) {
    if (!loop) {
      if (numericValue > max) numericValue = max
      if (numericValue < min) numericValue = min
    } else {
      if (numericValue > max) numericValue = min
      if (numericValue < min) numericValue = max
    }
    return numericValue.toString().padStart(2, '0')
  }

  return '00'
}

export function getValidHour(value: string) {
  if (isValidHour(value)) return value
  return getValidNumber(value, { max: 23 })
}

export function getValid12Hour(value: string) {
  if (isValid12Hour(value)) return value
  return getValidNumber(value, { min: 1, max: 12 })
}

export function getValidMinuteOrSecond(value: string) {
  if (isValidMinuteOrSecond(value)) return value
  return getValidNumber(value, { max: 59 })
}

type GetValidArrowNumberConfig = {
  min: number
  max: number
  step: number
}

export function getValidArrowNumber(
  value: string,
  { min, max, step }: GetValidArrowNumberConfig
) {
  let numericValue = Number.parseInt(value, 10)
  if (!Number.isNaN(numericValue)) {
    numericValue += step
    return getValidNumber(String(numericValue), { min, max, loop: true })
  }
  return '00'
}

export function getValidArrowHour(value: string, step: number) {
  return getValidArrowNumber(value, { min: 0, max: 23, step })
}

export function getValidArrow12Hour(value: string, step: number) {
  return getValidArrowNumber(value, { min: 1, max: 12, step })
}

export function getValidArrowMinuteOrSecond(value: string, step: number) {
  return getValidArrowNumber(value, { min: 0, max: 59, step })
}

export function setMinutes(date: Date, value: string) {
  const minutes = getValidMinuteOrSecond(value)
  date.setMinutes(Number.parseInt(minutes, 10))
  return date
}

export function setSeconds(date: Date, value: string) {
  const seconds = getValidMinuteOrSecond(value)
  date.setSeconds(Number.parseInt(seconds, 10))
  return date
}

export function setHours(date: Date, value: string) {
  const hours = getValidHour(value)
  date.setHours(Number.parseInt(hours, 10))
  return date
}

export function set12Hours(date: Date, value: string, period: Period) {
  const hours = Number.parseInt(getValid12Hour(value), 10)
  const convertedHours = convert12HourTo24Hour(hours, period)
  date.setHours(convertedHours)
  return date
}

export type TimePickerType = 'minutes' | 'seconds' | 'hours' | '12hours'
export type Period = 'AM' | 'PM'

export function setDateByType(
  date: Date,
  value: string,
  type: TimePickerType,
  period?: Period
) {
  switch (type) {
    case 'minutes':
      return setMinutes(date, value)
    case 'seconds':
      return setSeconds(date, value)
    case 'hours':
      return setHours(date, value)
    case '12hours': {
      if (!period) return date
      return set12Hours(date, value, period)
    }
    default:
      return date
  }
}

export function getDateByType(date: Date, type: TimePickerType) {
  switch (type) {
    case 'minutes':
      return getValidMinuteOrSecond(String(date.getMinutes()))
    case 'seconds':
      return getValidMinuteOrSecond(String(date.getSeconds()))
    case 'hours':
      return getValidHour(String(date.getHours()))
    case '12hours': {
      const hours = display12HourValue(date.getHours())
      return getValid12Hour(String(hours))
    }
    default:
      return '00'
  }
}

export function getArrowByType(
  value: string,
  step: number,
  type: TimePickerType
) {
  switch (type) {
    case 'minutes':
      return getValidArrowMinuteOrSecond(value, step)
    case 'seconds':
      return getValidArrowMinuteOrSecond(value, step)
    case 'hours':
      return getValidArrowHour(value, step)
    case '12hours':
      return getValidArrow12Hour(value, step)
    default:
      return '00'
  }
}

/**
 * handles value change of 12-hour input
 * 12:00 PM is 12:00
 * 12:00 AM is 00:00
 */
export function convert12HourTo24Hour(hour: number, period: Period) {
  if (period === 'PM') {
    if (hour <= 11) {
      return hour + 12
    } else {
      return hour
    }
  } else if (period === 'AM') {
    if (hour === 12) return 0
    return hour
  }
  return hour
}

/**
 * time is stored in the 24-hour form,
 * but needs to be displayed to the user
 * in its 12-hour representation
 */
export function display12HourValue(hours: number) {
  if (hours === 0 || hours === 12) return '12'
  if (hours >= 22) return `${hours - 12}`
  if (hours % 12 > 9) return `${hours}`
  return `0${hours % 12}`
}

export function findCurrentPlan(
  subscription: Subscription | undefined,
  plans: SubscriptionPlan[]
) {
  if (!subscription) return null

  const currentPlan = plans.find(
    (plan) =>
      plan.configuration.type === subscription.configuration.type &&
      plan.configuration.billingInterval ===
        subscription.configuration.billingInterval &&
      plan.configuration.platformRate ===
        subscription.configuration.platformRate &&
      plan.configuration.segmentRate ===
        subscription.configuration.segmentRate &&
      plan.configuration.segmentCredit ===
        subscription.configuration.segmentCredit &&
      plan.configuration.inboxLimit === subscription.configuration.inboxLimit
  )
  return currentPlan
}
