import {
  BadgeCheck,
  MessagesSquare,
  Voicemail,
  Smartphone,
  Zap,
  Filter,
  Bell,
} from 'lucide-react'
import {
  ConversationProps,
  FeatureCardProps,
  FeatureGridItemProps,
  FormSubmissionProps,
  MessageProps,
} from './types'
import { FlagIcon, ViewGridAddIcon } from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import { Customer } from '@/api/types'

export const CONVERSATIONS: ConversationProps[] = [
  {
    title: 'for conversions',
    messages: [
      {
        id: '0-2',
        body: "Hey Stephen, thanks for being a VIP 🙏. We're inviting a select few to shop the new collection before it drops. Any interest?",
        direction: 'outgoing',
      },
      {
        id: '0-1',
        body: "Yes! I love the stuff you guys make. I'm definitely interested.",
        direction: 'incoming',
      },
      {
        id: '0-4',
        body: "🤫 https://hsms.co/fall-drop. You'll get 20% off if you shop before the collection is open to the public.",
        direction: 'outgoing',
      },
      { id: '0-3', body: 'Amazing, thank you!!!', direction: 'incoming' },
    ],
  },
  {
    title: 'for nightlife',
    messages: [
      {
        id: '1-1',
        body: "Hey! I'm interested in booking a table for bottle service this Friday night.",
        direction: 'incoming',
      },
      {
        id: '1-2',
        body: 'Absolutely! How many people will be in your party?',
        direction: 'outgoing',
      },
      { id: '1-3', body: "We'll have 8 people.", direction: 'incoming' },
      {
        id: '1-4',
        body: 'Great! We have a table available. Shall I book it for you?',
        direction: 'outgoing',
      },
      { id: '1-5', body: 'Yes, please. Thank you!', direction: 'incoming' },
    ],
  },
  {
    title: 'for fitness',
    messages: [
      {
        id: '2-1',
        body: "Hi, we noticed you haven't visited our studio for a month. Would you like to book a class?",
        direction: 'outgoing',
      },
      {
        id: '2-2',
        body: "I've been busy recently but I'd love to get back. What classes are available?",
        direction: 'incoming',
      },
      {
        id: '2-3',
        body: 'We have a Yoga class tomorrow at 6pm. Would that work for you?',
        direction: 'outgoing',
      },
      {
        id: '2-4',
        body: 'Yes, that works for me. Please book it.',
        direction: 'incoming',
      },
      {
        id: '2-5',
        body: "Great! You're all set for the class tomorrow. See you then!",
        direction: 'outgoing',
      },
    ],
  },
  // {
  //   title: 'for e-commerce',
  //   messages: [
  //     {
  //       id: '3-1',
  //       body: 'Hey! We just launched a new collection on our store. Check it out!',
  //       direction: 'outgoing'
  //     },
  //     { id: '3-2', body: 'That sounds great! Do you have any new jackets?', direction: 'incoming' },
  //     {
  //       id: '3-3',
  //       body: "Yes, we do! Here's the link to our new jackets collection.",
  //       direction: 'outgoing'
  //     },
  //     { id: '3-4', body: "I loved the blue jacket. I'm buying it now.", direction: 'incoming' },
  //     {
  //       id: '3-5',
  //       body: 'Awesome! Let us know if you need any help with your purchase.',
  //       direction: 'outgoing'
  //     }
  //   ]
  // }
]

export const FEATURE_CARDS: FeatureCardProps[] = [
  {
    title: 'Big messaging features. Built for big teams.',
    description:
      'Powerful text messaging features for personalized bulk and 1:1 messaging. Manage conversations effortlessly, assign to teammates, and track results, all in one seamless experience.',
    image: {
      src: '/images/feature-card-messaging.png',
      width: 2160,
      height: 912,
    },
    wide: true,
  },
  {
    title: 'Segment, send, automate.',
    description:
      'Use Helios to segment contacts, send targeted messages, and automate campaigns, effortlessly reaching the right customers at the right time.',
    image: {
      src: '/images/feature-card-segment.png',
      width: 1200,
      height: 912,
    },
  },
  {
    title: 'We worry about compliance, you just send.',
    description:
      "With Helios, TCPA compliance is handled for you. Just message, and we'll add the required language as needed.",
    image: {
      src: '/images/feature-card-tcpa.png',
      width: 1200,
      height: 912,
    },
    objectPosition: 'top',
  },
  {
    title: 'Turn on your new lead engine.',
    description:
      'Build and embed forms on your site or landing page. Collect contacts and respond right away via SMS. The fastest way to get the conversation started, guaranteed.',
    image: {
      src: '/images/feature-card-leads.png',
      width: 2160,
      height: 912,
    },
    wide: true,
    reversed: true,
  },
]
export const FEATURE_GRID_ITEMS: FeatureGridItemProps[] = [
  {
    icon: MessagesSquare,
    title: 'Text Messaging for Teams',
    description:
      'Converse on desktop or iOS, assign conversations to team members, close when complete, and benefit from MMS and link tracking.',
  },
  {
    icon: FlagIcon,
    title: 'Campaign Management',
    description:
      'Easily select audiences, create and send or schedule bulk messages, view performance, and utilize message templates.',
  },
  {
    icon: BadgeCheck,
    title: 'Built-in TCPA Compliance',
    description:
      'Helios takes care of compliance, automatically adding the required TCPA language so you can focus on messaging.',
  },
  {
    icon: Bell,
    title: 'Form Builder & Integration',
    description:
      'Create and embed forms in Helios. Respond to submissions via SMS.',
  },
  {
    icon: Filter,
    title: 'Advanced Segmentation',
    description:
      'Segment contacts using prebuilt templates or AI prompts, targeting customers with precision.',
  },
  {
    icon: Zap,
    title: 'Smart Automations',
    description:
      'Automatically send messages based on keywords, segment changes, or form submissions.',
  },
  {
    icon: Smartphone,
    title: 'Helios iOS App',
    description:
      'Manage your campaigns and conversations on the go with our dedicated iOS app.',
  },
  {
    icon: Voicemail,
    title: 'Voicemail to SMS',
    description:
      'Receive voicemails on your Helios number and have them transcribed and put into conversations to respond via SMS.',
  },
  {
    icon: ViewGridAddIcon,
    title: 'Apps & Integrations',
    description:
      'Integrate with various platforms, manage lists, granular permissions, multiple numbers, unlimited users, and more.',
  },
]
export const FORM_SUBMISSIONS: FormSubmissionProps[] = [
  {
    title: 'Book a reservation',
    fields: [
      {
        name: 'name',
        value: 'John Brown',
        type: 'text',
      },
      {
        name: 'reservationDate',
        value: DateTime.local().plus({ days: 1 }).toISODate()!,
        type: 'date',
      },
      {
        name: 'numberOfGuests',
        value: '18',
        type: 'number',
      },
      {
        name: 'phoneNumber',
        value: '+1 (555) 555-5555',
        type: 'phone',
      },
      {
        name: 'message',
        value: "I'm coming with a group for a surprise birthday party.",
        type: 'textarea',
      },
    ],
  },
]
export const CONTACTS_CHART_DATA = [
  {
    date: DateTime.local().minus({ days: 1 }).toISODate(),
    total: 95,
  },
  {
    date: DateTime.local().minus({ days: 2 }).toISODate(),
    total: 90,
  },
  {
    date: DateTime.local().minus({ days: 3 }).toISODate(),
    total: 85,
  },
  {
    date: DateTime.local().minus({ days: 4 }).toISODate(),
    total: 80,
  },
  {
    date: DateTime.local().minus({ days: 5 }).toISODate(),
    total: 75,
  },
  {
    date: DateTime.local().minus({ days: 6 }).toISODate(),
    total: 70,
  },
  {
    date: DateTime.local().minus({ days: 7 }).toISODate(),
    total: 65,
  },
  {
    date: DateTime.local().minus({ days: 8 }).toISODate(),
    total: 60,
  },
  {
    date: DateTime.local().minus({ days: 9 }).toISODate(),
    total: 90,
  },
  {
    date: DateTime.local().minus({ days: 10 }).toISODate(),
    total: 95,
  },
].reverse()

export const NEW_CONTACTS: Omit<
  Customer,
  'updatedAt' | 'org' | 'gym' | 'properties'
>[] = [
  {
    id: '0',
    firstName: 'John',
    lastName: 'Brown',
    phoneNumber: '+1-555-555-5555',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '1',
    firstName: 'Tony',
    lastName: 'Stark',
    phoneNumber: '+1-555-555-5556',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '2',
    firstName: 'Bruce',
    lastName: 'Wayne',
    phoneNumber: '+1-555-555-5557',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '3',
    firstName: 'Peter',
    lastName: 'Parker',
    phoneNumber: '+1-555-555-5558',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '4',
    firstName: 'Walter',
    lastName: 'White',
    phoneNumber: '+1-555-555-5559',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '5',
    firstName: 'Jon',
    lastName: 'Snow',
    phoneNumber: '+1-555-555-5560',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '6',
    firstName: 'Daenerys',
    lastName: 'Targaryen',
    phoneNumber: '+1-555-555-5561',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '7',
    firstName: 'Sherlock',
    lastName: 'Holmes',
    phoneNumber: '+1-555-555-5562',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '8',
    firstName: 'James',
    lastName: 'Bond',
    phoneNumber: '+1-555-555-5563',
    createdAt: DateTime.local().toISODate()!,
  },
  {
    id: '9',
    firstName: 'Luke',
    lastName: 'Skywalker',
    phoneNumber: '+1-555-555-5564',
    createdAt: DateTime.local().toISODate()!,
  },
]
export const ONE_ON_ONE_MESSAGES: MessageProps[] = [
  {
    id: '0-2',
    body: 'Hi Jane, just a reminder that your appointment is tomorrow at 2pm. See you then!',
    direction: 'outgoing',
  },
  {
    id: '0-1',
    body: "I'm sorry, I can't make it. Can we reschedule for same time next week?",
    direction: 'incoming',
  },
  {
    id: '0-4',
    body: "No problem. I've rescheduled your appointment for next week.",
    direction: 'outgoing',
  },
  { id: '0-3', body: 'Thank you so much', direction: 'incoming' },
]
export const ONE_ON_ONE_MESSAGES_FITNESS: MessageProps[] = [
  {
    id: '0-2',
    body: 'Hi Jane, just a reminder about your personal training session tomorrow at 2pm. Excited to see you!',
    direction: 'outgoing',
  },
  {
    id: '0-1',
    body: 'I need to cancel, unfortunately. Is next Thursday at the same time available?',
    direction: 'incoming',
  },
  {
    id: '0-4',
    body: 'Absolutely, Jane. Your session is now set for next Thursday. Keep up your great work till then!',
    direction: 'outgoing',
  },
  {
    id: '0-3',
    body: 'Awesome, thanks for being so flexible!',
    direction: 'incoming',
  },
]
export const MMS_MESSAGES: MessageProps[] = [
  {
    id: '0-2',
    body: 'Our new summer menu is here. Make your reservation today! hsms.co/3j2k4',
    media: [
      {
        contentType: 'image/jpeg',
        url: '/images/summer-menu.jpg',
      },
    ],
    direction: 'outgoing',
  },
]
export const MMS_MESSAGES_FITNESS: MessageProps[] = [
  {
    id: '0-2',
    body: 'Great class today guys! Someone left this water bottle behind. Let me know if it’s yours!',
    media: [
      {
        contentType: 'image/jpeg',
        url: '/images/water-bottle.jpg',
      },
    ],
    direction: 'outgoing',
  },
]
export const CTA_MESSAGES: MessageProps[] = [
  {
    id: '0-1',
    body: 'Helios offers best in class business texting tools to grow your business 📈.',
    direction: 'outgoing',
  },
  {
    id: '0-2',
    body: 'Sign up and start texting today!',
    direction: 'incoming',
  },
]
export const VOICEMAIL_MESSAGES: MessageProps[] = [
  {
    id: '0-2',
    body: "Hi, this is John. I'm planning a birthday party and wanted to find out about booking a table for next Friday. ",
    media: [
      {
        contentType: 'audio/mp3',
        url: '#',
      },
    ],
    direction: 'incoming',
  },
  {
    id: '0-1',
    body: "Hey John, thanks for reaching out. Happy birthday! 🎉 We'd love to help you book a table. How many people will be in your group?",
    direction: 'outgoing',
  },
]

export const VOICEMAIL_MESSAGES_FITNESS: MessageProps[] = [
  {
    id: '0-2',
    body: 'Hi, this is John. Is there any openings for the cycling class tomorrow morning? Please let me know, thanks',
    media: [
      {
        contentType: 'audio/mp3',
        url: '#',
      },
    ],
    direction: 'incoming',
  },
  {
    id: '0-1',
    body: "Hey John, thanks for reaching out. Yes we have a few spots left. I've booked you in for tomorrow morning. See you then!",
    direction: 'outgoing',
  },
]
